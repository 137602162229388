import { FC, ReactElement } from 'react';
import { history } from 'umi';
import { Grid } from 'antd';
import { useSelector } from 'dva';
import { LoginPage, Form, Typography } from 'iglooform';
import { useIntl } from 'react-intl';
import { SwitchLang } from '@/components/switch-lang';
import { setPassword } from '@/service/global';
import { reg, chineseReg } from '@/utils/reg';
import _ from 'lodash';
import styles from '../index.less';

const CreatePasswordPage: FC = (): ReactElement => {
  const { formatMessage } = useIntl();
  const { md } = Grid.useBreakpoint();
  const form = Form.useForm();
  const symbols = `~\`!@#$%^&*()_-+={[}]|\\:;"'<,>.?/`;

  const { token } = useSelector<ReduxState, GlobalState>(
    (state) => state.global,
  );

  const handleSubmit = _.throttle(
    () => {
      const password = form.getFieldValue('password');
      if (!!token) {
        setPassword(password, token as string)
          .then((res) => {
            if (res.status === 'ok') {
              history.replace('/password/result/success', { type: 'create' });
            }
          })
          .catch((err) => console.error(err));
      }
    },
    500,
    { leading: true, trailing: false },
  );

  return (
    <LoginPage showAppStore>
      <SwitchLang
        className={md ? styles.selectLang : styles.selectLangMobile}
      />
      <div className={md ? styles.loginContent : styles.loginContentMobile}>
        <div className={styles.title}>
          <Typography level="h1">
            {formatMessage({
              id: 'Create Your Password',
              defaultMessage: 'Create Your Password',
            })}
          </Typography>
        </div>
        <Form
          form={form}
          config={{
            type: 'Login',
            elements: [
              {
                type: 'Password',
                label: formatMessage({
                  id: 'Password',
                  defaultMessage: 'Password',
                }),
                name: 'password',
                fullRow: true,
                required: true,
                initialValue: '',
                extra: formatMessage(
                  {
                    id: `Password Validate`,
                  },
                  { symbols },
                ),
                rules: [
                  {
                    validator: (_, value: string) => {
                      if (
                        !!value &&
                        (!reg.test(value) || chineseReg.test(value))
                      ) {
                        return Promise.reject(
                          new Error(
                            formatMessage({
                              id: 'The password is not validated',
                            }),
                          ),
                        );
                      } else {
                        return Promise.resolve();
                      }
                    },
                  },
                ],
              },
              {
                type: 'Password',
                label: formatMessage({
                  id: 'Confirm Password',
                  defaultMessage: 'Confirm Password',
                }),
                name: 'confirmPassword',
                fullRow: true,
                required: true,
                initialValue: '',
                rules: [
                  {
                    validator: (_, value: string) => {
                      const p = form.getFieldValue('password');
                      if (!!value && !!p && p !== value) {
                        return Promise.reject(
                          new Error(
                            formatMessage({
                              id: 'The two passwords are inconsistent',
                            }),
                          ),
                        );
                      } else {
                        return Promise.resolve();
                      }
                    },
                  },
                ],
                antdDependencies: ['password'],
              },
            ],
          }}
          onSubmit={handleSubmit}
          locales={{
            submitText: formatMessage({
              id: 'Save Password',
              defaultMessage: 'Save Password',
            }),
            validateMessages: {
              required: formatMessage({ id: 'Required' }),
            },
          }}
        />
      </div>
    </LoginPage>
  );
};

export default CreatePasswordPage;
